// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  forgotPasswordConfirm,
} from "../../helpers/";

import { APICore, setAuthorization } from "../../helpers/api/apiCore";
import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

import { getPermisos } from "../../services/auth";

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  try {
    const response = yield call(loginApi, { username, password });//lLAMADO AL BACK
    const user = response.data;//RESPUESTA DEL BACK
    // NOTE - You can change this according to response format from your api
    api.setLoggedInUser(user);// GUARDA EL USUARIO EN EL LOCALSTORAGE
    setAuthorization(user["token"]); // GUARDA EL TOKEN EN EL LOCALSTORAGE
    getPermisos(); // LLAMA A LA FUNCION QUE TRAE LOS PERMISOS DEL USUARIO GUARDA EN EL SESSIONSTORAGE
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user)); // GUARDA EL USUARIO EN EL REDUX
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    yield call(logoutApi); // LLAMA AL BACK PARA EL LOGOUT
    api.setLoggedInUser(null); // BORRA EL USUARIO DEL LOCALSTORAGE
    setAuthorization(null); // BORRA EL TOKEN DEL LOCALSTORAGE
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {})); // BORRA EL USUARIO DEL REDUX
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({ payload: { usuario, password, confirmPassword } }) {
  try {
    // const response = yield call(signupApi, { usuario, password });
    // const user = response.data;
    // // api.setLoggedInUser(user);
    // // setAuthorization(user['token']);
    // yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }) {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

function* forgotPasswordChange({ payload: { data } }) {
  try {
    const response = yield call(forgotPasswordConfirm, data);
    yield put(
      authApiResponseSuccess(
        AuthActionTypes.FORGOT_PASSWORD_CHANGE,
        response.data
      )
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error)
    );
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchForgotPasswordChange),
  ]);
}

export default authSaga;
