import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEs from './locales/es/translation.json';
//translations
const resources = {
    es: {
        translation: translationEs,
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en', // use en if el lng detectado no está disponible

        keySeparator: false, // no usamos claves en los mensajes de formulario.bienvenido


        interpolation: {
            escapeValue: false, // react  ya es seguro de xss
        },
    });

export default i18n;
