import React from "react";
import { Route, Redirect } from "react-router-dom";

import { APICore } from "../helpers/api/apiCore";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const api = new APICore();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (api.isUserAuthenticated() === false) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: "/account/login",
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
